import { Button } from '@mui/material'
import { buttonStyles } from '../../styles/Material-UI/Buttons/FormButton'
import React from 'react'
import cx from 'classnames'

export default function ConfirmModal({
	msg,
	open,
	id,
	close,
	deletePortfolio,
}) {
	const buttons = buttonStyles()

	return (
		<>
			{open ? (
				<div className='fixed w-full h-full bg-slate-900/30 flex items-center justify-center z-50'>
					<div className='relative h-72 w-[500px] bg-dark_gray rounded-xl flex items-center justify-center text-4xl p-8 pb-[69px]'>
						{msg}
						<div className='absolute bottom-0 flex justify-center w-full pb-8'>
							<Button
								variant='contained'
								className='bg-magenta-reg magenta-hover !mr-4'
								onClick={() => {
									deletePortfolio(id)
									close()
								}}
							>
								Confirm
							</Button>
							<Button
								variant='outlined'
								className={cx(buttons.cancelButton, '!text-yellow !ml-4')}
								onClick={close}
							>
								Cancel
							</Button>
						</div>
					</div>
				</div>
			) : null}
		</>
	)
}
