import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

export default function NavLink({ children, path, active }) {
	return (
		<Link to={path ? path : '/'} className='w-full'>
			<li
				className={cx(
					'py-3 pb-4 px-2 cursor-pointer w-full hover:bg-light_gray hover:text-yellow transition-colors h-13 flex flex-col justify-center items-center pt-4',
					active ? 'bg-light_gray text-yellow' : ''
				)}
			>
				{children}
			</li>
		</Link>
	)
}
