import React, { useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

export default function ToastMessage({ success, visible, msg }) {
	useEffect(() => {
		setHidden(!visible)
	}, [visible])

	const [hidden, setHidden] = useState(!visible)
	return (
		<div
			className={
				!hidden
					? 'fixed top-16 transition-all duration-500 z-40 left-0 right-0 m-auto max-w-fit cursor-pointer'
					: 'fixed -top-[52px] transition-all duration-500 z-40 left-0 right-0 m-auto max-w-fit cursor-pointer'
			}
			onClick={() => setHidden(true)}
		>
			<div
				className={
					success ? 'bg-green-700 p-4 rounded-[4px]' : 'bg-red-700 p-4 rounded-[4px]'
				}
			>
				{success ? (
					<CheckIcon className='mr-4 bottom-[2px] relative' />
				) : (
					<CloseIcon className='mr-4 bottom-[2px] relative' />
				)}
				{msg}
			</div>
		</div>
	)
}
