import { makeStyles } from '@mui/styles'
import { rgbToHex } from '@mui/system'

export const inputStyles = makeStyles((theme) => ({
	contactInput: {
		width: '100%',
		'& input, & textarea': {
			color: 'rgb(209, 213, 219)',
		},
		'& .MuiSelect-select': {
			textAlign: 'left',
			color: 'rgb(209, 213, 219)',
		},
		'& .MuiOutlinedInput-root fieldset': {
			borderColor: 'rgb(209, 213, 219)',
		},
		'& label': {
			color: 'rgb(209, 213, 219)',
		},
		'& label.Mui-focused': {
			color: 'hsl(42, 100%, 50%)',
		},
		'& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				border: '1px solid white !important',
			},
			'&.Mui-focused fieldset': {
				borderColor: 'hsl(42, 100%, 50%) !important',
			},
		},
		'& .MuiSelect-iconOutlined': {
			color: 'rgb(209, 213, 219)',
		},
		'& input:valid + fieldset': {
			borderColor: 'hsl(42, 100%, 50%)',
		},
		'& select:valid + fieldset': {
			borderColor: 'hsl(42, 100%, 50%)',
		},
	},
	forceHighlight: {
		'& label': {
			color: 'hsl(42, 100%, 50%)',
		},
	},
	forceBorder: {
		'& .MuiOutlinedInput-root fieldset': {
			borderColor: 'hsl(42, 100%, 50%)',
		},
	},
}))
