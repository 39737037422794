import React, { useContext, useEffect } from 'react'
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance'
import CameraIcon from '@mui/icons-material/Camera'
import GroupsIcon from '@mui/icons-material/Groups'
import { buttonStyles } from '../styles/Material-UI/Buttons/FormButton'
import { AppContext } from '../utils/context'
import image from '../assets/img/Wenze_B&W.png'
import StarIcon from '@mui/icons-material/Star'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import ServiceStep from '../components/Services/ServiceStep'
import Divider from '../components/Divider'

const Services = () => {
	const buttons = buttonStyles()

	const { setPageActive } = useContext(AppContext)

	useEffect(() => {
		setPageActive('services')
	})
	return (
		<div className='w-full flex flex-col items-center'>
			{/* <img
				src={image}
				alt='Wenze B&W'
				className='xl:h-screen z-20 w-full object-cover'
			/> */}
			<div className='p-16 lg:px-8 md:!px-4 flex flex-col items-center justify-center text-2xl text max-w-[85ch]'>
				<h3 className='text-6xl mb-16 font-miller'>Services</h3>
				<h5 className='text-3xl text-magenta font-semibold'>
					Your Journey to Captivating Photos Starts Now
				</h5>
				<p className='my-6'>
					Navigating the world of professional photography can seem daunting. But
					with me, I've made the process straightforward and enjoyable. Just follow
					these steps, and together, we'll create moments that last a lifetime!
				</p>

				{/* <ServiceStep
					step={1}
					heading={'Choose the Style of Photography Tailored to You'}
				>
					<div className='flex flex-col items-center'>
						<div className='flex'>
							<div className='w-1/3 px-6 pb-6 flex flex-col justify-center items-center'>
								<div className='w-[48px] h-[48px] bg-[#191919] rounded-full flex justify-center items-center mb-4	'>
									<CameraEnhanceIcon fontSize='large' />
								</div>
								<h4 className='text-[28px] font-bold mb-4'>Personalized Portraits</h4>
								<p className='font-normal text-xl max-w-[36ch]'>
									Capture the essence of individuality. Ideal for those who value
									authenticity and wish to stand out.
								</p>
							</div>
							<div className='w-1/3 px-6 pb-6 flex flex-col justify-center items-center'>
								<div className='w-[48px] h-[48px] bg-[#191919] rounded-full flex justify-center items-center mb-4	'>
									<CameraIcon fontSize='large' />
								</div>
								<h4 className='text-[28px] font-bold mb-4'>Specialized Headshots</h4>
								<p className='font-normal text-xl max-w-[36ch]'>
									Beyond the conventional. Crafted with precision to ensure every look
									communicates more than just a face.
								</p>
							</div>
							<div className='w-1/3 px-6 pb-6 flex flex-col justify-center items-center'>
								<div className='w-[48px] h-[48px] bg-[#191919] rounded-full flex justify-center items-center pb-1 mb-4	'>
									<GroupsIcon fontSize='large' />
								</div>
								<h4 className='text-[28px] font-bold mb-4'>Group Photography</h4>
								<p className='font-normal text-xl max-w-[36ch]'>
									Celebrate togetherness. Perfect for any group, big or small, wishing to
									capture their shared moments with elegance.
								</p>
							</div>
						</div>
					</div>
				</ServiceStep> */}

				<ServiceStep step={1} heading={'Pick Your Iconic Cincinnati Location'}>
					<ul>
						<li className='text-left list-disc ml-4'>Fountain Square</li>
						<li className='text-left list-disc ml-4'>
							Over-the-Rhine Historic District
						</li>
						<li className='text-left list-disc ml-4'>Cincinnati Riverfront Park</li>
						<li className='text-left list-disc ml-4'>Eden Park Overlook</li>
						<li className='text-left list-disc ml-4'>
							Location requests are welcomed
						</li>
					</ul>
				</ServiceStep>

				<ServiceStep step={2} heading={'Choose Your Package'}>
					<div>
						<div className='text-left mb-8'>
							<h6 className='text-2xl text-magenta mb-4'>Personalized Portraits</h6>
							{/* <div className='w-[36px] h-[36px] bg-[#191919] rounded-full flex justify-center items-center'>
								<CameraEnhanceIcon fontSize='medium' />
							</div> */}
							<div className='flex md:flex-wrap gap-4'>
								<div className='w-1/2 md:w-full'>
									<p className='font-semibold'>Full session - $200</p>
									<ul className='py-2 pl-6'>
										<li className='list-disc'>60 minutes</li>
										<li className='list-disc'>
											Includes a pre-session consultation to align visions
										</li>
									</ul>
									<p className=''>
										Unveil the essence of who you are. Whether it's for personal branding
										or a cherished memory, I craft each portrait with care.
									</p>
								</div>
								<div className='w-1/2 md:w-full'>
									<p className='font-semibold'>Half session - $120</p>
									<ul className='py-2 pl-6'>
										<li className='list-disc'>30 minutes</li>
										<li className='list-disc'>
											Quick pre-session consultation included for alignment
										</li>
									</ul>
									<p className=''>
										A shorter session, perfect for those who want quality portraits in
										less time.
									</p>
								</div>
							</div>
						</div>

						<div className='text-left mb-8 bg-yellow/60 py-4 px-8 md:px-4 rounded-lg'>
							<div className='flex justify-between'>
								<h6 className='text-2xl mb-4'>Specialized Headshots</h6>
								<div className='flex gap-2 items-center'>
									<p className='text-xl font-semibold'>Featured</p>
									<StarIcon fontSize='large' />
								</div>
							</div>
							{/* <div className='w-[36px] h-[36px] bg-[#191919] rounded-full flex justify-center items-center'>
								<CameraEnhanceIcon fontSize='medium' />
							</div> */}
							<div className='flex md:flex-wrap gap-4'>
								<div className='w-1/2 md:w-full'>
									<p className='font-semibold'>Full session - $125</p>
									<ul className='py-2 pl-6'>
										<li className='list-disc'>60 minutes</li>
										<li className='list-disc'>
											Pre-session consultation included to ensure we capture the look
											you're aiming for
										</li>
									</ul>
									<p className=''>
										Elevate your professional presence or showcase your artistry. Each
										headshot is more than just a photo, it's a statement.
									</p>
								</div>
								<div className='w-1/2 md:w-full'>
									<p className='font-semibold'>Half session - $80</p>
									<ul className='py-2 pl-6'>
										<li className='list-disc'>30 minutes</li>
										<li className='list-disc'>
											Brief consultation included to outline expectations
										</li>
									</ul>
									<p className=''>
										For when you need a quality headshot without the extended session.
									</p>
								</div>
							</div>
						</div>

						<div className='text-left'>
							<h6 className='text-2xl text-magenta mb-4'>Group Photography</h6>
							{/* <div className='w-[36px] h-[36px] bg-[#191919] rounded-full flex justify-center items-center'>
								<CameraEnhanceIcon fontSize='medium' />
							</div> */}
							<div className='flex md:flex-wrap gap-4'>
								<div className='w-1/2 md:w-full'>
									<p className='font-semibold'>Full session - $300</p>
									<ul className='py-2 pl-6'>
										<li className='list-disc'>60 minutes</li>
										<li className='list-disc'>
											Price assumes 2-4 people and increase as group size increases
										</li>
										<li className='list-disc'>
											Group dynamics consultation to ensure a cohesive final product
										</li>
									</ul>
									<p className=''>
										From dynamic duos to entire teams, I'll help you capture the synergy
										and spirit of your group.
									</p>
								</div>
								<div className='w-1/2 md:w-full'>
									<p className='font-semibold'>Half session - $180</p>
									<ul className='py-2 pl-6'>
										<li className='list-disc'>30 minutes</li>
										<li className='list-disc'>
											Price assumes 2-4 people and increase as group size increases
										</li>
										<li className='list-disc'>
											Quick consultation for group coordination
										</li>
									</ul>
									<p className=''>
										Ideal for smaller groups or those on tighter schedules.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className='py-8 text-left'>
						<Divider />
						<div className='pt-8'>
							<h6 className='font-semibold'>Extended Time</h6>
							<p>
								If you feel our session requires additional time, it can be added at a
								rate of $50 for every additional 30 minutes. Let's make sure we get
								every shot you're dreaming of!
							</p>
						</div>
						<div className='pt-6'>
							<h6 className='font-semibold'>Student Discount</h6>
							<p>
								Being passionate shouldn’t break the bank. I offer a 10% discount for
								students with a valid college ID. Because I know firsthand the
								challenges of following your dreams on a budget.
							</p>
						</div>
					</div>
				</ServiceStep>

				<ServiceStep step={3} heading={"Reach Out And Let's Craft Your Vision"}>
					<div className='text-left'>
						<p>
							Ensuring we're aligned in what you envision is crucial to me. Whether
							it's for headshots, branding, or simply a celebratory session – I'm
							dedicated to capturing it perfectly and ensuring you feel comfortable
							throughout.
						</p>
						<div className='w-full flex justify-center mt-8'>
							<Link to='/contact'>
								<Button
									variant='contained'
									className={[buttons.bookButton, 'bg-magenta-reg magenta-hover']}
								>
									Book Now
								</Button>
							</Link>
						</div>
					</div>
				</ServiceStep>
			</div>
		</div>
	)
}

export default Services
