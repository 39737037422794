import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useForm, Controller } from 'react-hook-form'
import axios from 'axios'
import cx from 'classnames'

import { inputStyles } from '../../styles/Material-UI/Inputs/ContactInput'
import { Button, CircularProgress } from '@mui/material'
import { buttonStyles } from '../../styles/Material-UI/Buttons/FormButton'

export default function ContactForm({
	setSuccessfullySent,
	setToastVisible,
	setToastMessage,
}) {
	const classes = inputStyles()
	const buttons = buttonStyles()

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [service, setService] = useState('')
	const [date, setDate] = useState('')
	const [location, setLocation] = useState('')
	const [message, setMessage] = useState('')
	const [sending, setSending] = useState(false)

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		formState,
	} = useForm({
		// mode: 'onTouch',
	})

	const handleServiceChange = (e) => {
		setService(e.target.value)
	}

	const onSubmit = (e) => {
		e.preventDefault()
		setSending(true)

		axios
			.post(process.env.REACT_APP_BASE_URL + '/email', {
				name,
				email,
				service,
				date,
				location,
				message,
			})
			.then((response) => {
				if (response.status === 200) {
					setSending(false)
					setSuccessfullySent(true)
					setToastMessage('Message successfully sent')
					setToastVisible(true)

					setName('')
					setEmail('')
					setService('')
					setDate('')
					setLocation('')
					setMessage('')

					setTimeout(() => {
						setToastVisible(false)
					}, 5000)
				} else {
					setSending(false)
					setSuccessfullySent(false)
					setToastMessage('Something went wrong, please try again later')
					setToastVisible(true)

					setTimeout(() => {
						setToastVisible(false)
					}, 5000)
				}
			})
			.catch((error) => {
				setSending(false)
				setSuccessfullySent(false)
				setToastMessage('Something went wrong, please try again later')
				setToastVisible(true)

				setTimeout(() => {
					setToastVisible(false)
				}, 5000)
			})
	}

	return (
		<form
			className='w-full max-w-sm min-w-xs'
			onSubmit={(e) => onSubmit(e)}
			// noValidate
		>
			<TextField
				label='Name'
				variant='outlined'
				fullWidth
				margin='dense'
				value={name}
				onChange={(e) => setName(e.target.value)}
				className={
					name
						? [classes.contactInput, classes.forceHighlight]
						: classes.contactInput
				}
				required
			/>
			<TextField
				label='Email'
				variant='outlined'
				fullWidth
				margin='dense'
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				className={
					email
						? [classes.contactInput, classes.forceHighlight]
						: classes.contactInput
				}
				required
			/>
			<FormControl
				fullWidth
				margin='dense'
				className={
					service
						? [classes.contactInput, classes.forceHighlight, classes.forceBorder]
						: classes.contactInput
				}
			>
				<InputLabel id='demo-simple-select-label'>
					What are you interested in?
				</InputLabel>
				<Select
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					value={service}
					label='What are you interested in?'
					onChange={handleServiceChange}
					required
				>
					<MenuItem value={'headshots'}>Specialized Headshots</MenuItem>
					<MenuItem value={'portraits'}>Personal Portraits</MenuItem>
					<MenuItem value={'group'}>Group Photography</MenuItem>
					<MenuItem value={'other'}>Other</MenuItem>
					<MenuItem value={'chat'}>Just want to chat!</MenuItem>
				</Select>
			</FormControl>
			{service === 'headshots' ||
			service === 'couples' ||
			service === 'wedding' ? (
				<>
					<TextField
						label='Date & Time'
						variant='outlined'
						fullWidth
						margin='dense'
						value={date}
						onChange={(e) => setDate(e.target.value)}
						className={
							date
								? [classes.contactInput, classes.forceHighlight]
								: classes.contactInput
						}
						required
					/>
					<TextField
						label='Location'
						variant='outlined'
						fullWidth
						margin='dense'
						value={location}
						onChange={(e) => setLocation(e.target.value)}
						className={
							location
								? [classes.contactInput, classes.forceHighlight]
								: classes.contactInput
						}
						required
					/>
				</>
			) : null}
			<TextField
				label='Message/Details'
				variant='outlined'
				fullWidth
				margin='dense'
				value={message}
				onChange={(e) => setMessage(e.target.value)}
				className={
					message
						? [classes.contactInput, classes.forceHighlight, classes.forceBorder]
						: classes.contactInput
				}
				required
				multiline
				minRows='2'
				maxRows='5'
			/>
			<Button
				variant='contained'
				className={cx(buttons.formButton, 'bg-magenta-reg magenta-hover')}
				type='submit'
			>
				{sending ? (
					<CircularProgress size='1.5rem' sx={{ width: '100%', color: '#c38c0c' }} />
				) : (
					'Send'
				)}
			</Button>
		</form>
	)
}
