import { Route, Routes } from 'react-router-dom'

import './App.css'
import Home from './pages/Home'
import About from './pages/About'
import Portfolio from './pages/Portfolio'
import PortfolioPage from './components/Portfolio/PortfolioPage'
import Investment from './pages/Investment'
import Contact from './pages/Contact'
import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer/Footer'
import Edit from './pages/Edit'
import Services from './pages/Services'

function App() {
	return (
		<div
			className='App font-eaves text-gray-200 bg-main-gray-70 text-lg'
			style={{ paddingTop: '52px' }}
		>
			<Navbar />
			<Routes>
				<Route exact path='/' element={<Home />} />
				<Route exact path='/about' element={<About />} />
				<Route exact path='/portfolio' element={<Portfolio />} />
				<Route path='/portfolio/:id' element={<PortfolioPage />} />
				<Route exact path='/investment' element={<Investment />} />
				<Route exact path='/services' element={<Services />} />
				<Route exact path='/contact' element={<Contact />} />
				<Route exact path='/edit' element={<Edit />} />
			</Routes>
			<Footer />
		</div>
	)
}

export default App
