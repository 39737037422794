import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import './index.css'
import App from './App'
import Context from './utils/context'
import ScrollToTop from './components/Helper/ScrollToTop'

ReactDOM.render(
	<Context>
		<Router>
			<ScrollToTop />
			<App />
		</Router>
	</Context>,
	document.getElementById('root')
)
