import React, { useContext } from 'react'
import InstagramIcon from '@mui/icons-material/Instagram'
import { iconStyles } from '../../styles/Material-UI/Icons/Icons'
import { AppContext } from '../../utils/context'
import nameLogo from '../../assets/img/name-logo.svg'

export default function Footer() {
	const { mobile } = useContext(AppContext)

	const icons = iconStyles()

	return (
		<div
			className={
				mobile
					? 'w-full bg-dark_gray pt-8 pb-13 footer-border'
					: 'w-full bg-dark_gray py-8 footer-border'
			}
		>
			<div className='flex flex-col items-center py-4'>
				<a
					href='https://www.instagram.com/sr_photo_/'
					target='_blank'
					rel='noreferrer'
				>
					<InstagramIcon className={icons.instaIcon} />
				</a>

				<img
					src={nameLogo}
					width='350px'
					alt='Sean Reynolds Photography'
					className='-m-24 pointer-events-none'
				/>

				<p className='my-2'>
					Copyright &copy; {new Date().getFullYear()} Sean Reynolds Photography
				</p>
				<p>
					Website created by{' '}
					<a
						href='https://fennerstudios.com'
						className='hover:text-green-400 hover:underline'
						rel='noreferrer'
						target='_blank'
					>
						Fenner Studios
					</a>
				</p>
			</div>
		</div>
	)
}
