import React, { useContext, useRef } from 'react'
import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
import PortraitIcon from '@mui/icons-material/Portrait'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import ReviewsIcon from '@mui/icons-material/Reviews'
import EmailIcon from '@mui/icons-material/Email'
import NavLink from './NavLink'
import { AppContext } from '../../utils/context'
import { Link } from 'react-router-dom'
import logo from '../../assets/img/logo.svg'

export default function Navbar() {
	const { mobile, pageActive } = useContext(AppContext)

	const navRef = useRef()

	return (
		<>
			{mobile ? (
				<ul
					className={'flex fixed bottom-0 justify-around w-full bg-dark_gray z-50'}
				>
					<NavLink path='/' active={pageActive === 'home'}>
						<HomeIcon />
						<span className='leading-3 text-sm'>Home</span>
					</NavLink>
					<NavLink path='/about' active={pageActive === 'about'}>
						<PersonIcon />
						<span className='leading-3 text-sm'>About</span>
					</NavLink>
					<NavLink path='/portfolio' active={pageActive === 'portfolio'}>
						<PortraitIcon />
						<span className='leading-3 text-sm'>Portfolios</span>
					</NavLink>
					<NavLink path='/services' active={pageActive === 'services'}>
						<CameraAltIcon />
						<span className='leading-3 text-sm'>Services</span>
					</NavLink>
					<NavLink path='/contact' active={pageActive === 'contact'}>
						<EmailIcon />
						<span className='leading-3 text-sm'>Contact</span>
					</NavLink>
				</ul>
			) : null}

			<div
				className='flex justify-between items-center bg-dark_gray fixed top-0 w-full drop-shadow-md h-13 z-50'
				ref={navRef}
			>
				<Link to='/'>
					<img src={logo} alt='Logo' className=' cursor-pointer ml-3 h-full w-10' />
				</Link>
				{!mobile ? (
					<ul className='flex mr-4 z-50'>
						<NavLink path='/' active={pageActive === 'home'}>
							Home
						</NavLink>
						<NavLink path='/about' active={pageActive === 'about'}>
							About
						</NavLink>
						<NavLink path='/portfolio' active={pageActive === 'portfolio'}>
							Portfolios
						</NavLink>
						<NavLink path='/services' active={pageActive === 'services'}>
							Services
						</NavLink>
						<NavLink path='/contact' active={pageActive === 'contact'}>
							Contact
						</NavLink>
					</ul>
				) : null}
			</div>
		</>
	)
}
