import React from 'react'

const ServiceStep = ({ children, step, heading }) => {
	return (
		<div className='w-full p-6 md:p-4 rounded-xl bg-white/20 my-4 shadow-lg'>
			<span className='text-3xl text-left text-yellow block md:mb-4'>
				Step {step}
			</span>
			<h6 className='mb-8'>{heading}</h6>
			<div className='text-xl'>{children}</div>
		</div>
	)
}

export default ServiceStep
