import React, { useContext, useEffect } from 'react'
import cx from 'classnames'

import { AppContext } from '../utils/context'
import seanFull from '../assets/img/jpg/sean-full.jpg'

export default function About() {
	const { mobile, setPageActive } = useContext(AppContext)

	useEffect(() => {
		setPageActive('about')
	}, [])

	return (
		<div
			className={cx(
				'flex items-center screen-nav',
				mobile ? 'flex-col about-back-image' : ''
			)}
		>
			<div
				className={cx(
					'flex flex-col items-center px-8 pb-8',
					mobile ? 'w-full' : 'w-[55%]'
				)}
			>
				{/* <p className='max-w-xl text-5xl mt-12 font-miller'>
					Hi, I’m Sean! Nice to meet you.
				</p>
				<br /> */}
				<h3 className='text-6xl mt-12 mb-12 font-miller'>About Me</h3>
				<p
					className='max-w-xl text-
				xl'
				>
					Hello, I'm Sean, a portrait photographer specializing in capturing stunning
					images of musicians. With my background as a trombonist, I possess a deep
					understanding of what makes performers unique. This knowledge drives my
					passion for creating portraits that truly capture your essence.
				</p>
				<br />
				<p
					className='max-w-xl text-
				xl'
				>
					I believe that your portrait should reflect your unique style and sound.
					That's why I work closely with each of my clients to ensure that their
					portraits are tailored to their individual needs and preferences.
				</p>
				<br />
				<p
					className='max-w-xl text-
				xl'
				>
					If you're a musician looking for a portrait that encapsulates your unique
					style and sound, I'd love to work with you. Contact me today to schedule a
					session and let's create something truly unforgettable together!
				</p>
				<br />
			</div>
			{!mobile ? (
				<div className='h-full w-[45%]'>
					<img
						src={seanFull}
						alt='proto'
						height='1000px'
						className='min-h-full h-screen min-w-full w-screen object-cover'
					/>
				</div>
			) : null}
		</div>
	)
}
