import { Button } from '@mui/material'
import React from 'react'
import cx from 'classnames'

import { buttonStyles } from '../../styles/Material-UI/Buttons/FormButton'
import { Link } from 'react-router-dom'
import fullLogo from '../../assets/img/full-logo.svg'

export default function Hero() {
	const buttons = buttonStyles()

	return (
		<div className='flex flex-col items-center justify-evenly background-hero screen-nav relative'>
			<div className='w-full h-full bg-gray-900/50 absolute z-0'></div>
			<img
				src={fullLogo}
				alt='Sean Reynolds Photography'
				className='xl:h-[80vh] z-20'
			/>
			<div className='z-20 mb-12'>
				<p className='text-3xl mb-5 font-miller'>2023-2024 Sessions Available</p>
				<Link to='/contact'>
					<Button
						variant='contained'
						className={cx(buttons.bookButton, 'bg-magenta-reg magenta-hover')}
					>
						Book Now
					</Button>
				</Link>
			</div>
		</div>
	)
}
