import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import CloseIcon from '@mui/icons-material/Close'
import cx from 'classnames'

import PortfolioPageImage from './PortfolioPageImage'
import { iconStyles } from '../../styles/Material-UI/Icons/Icons'
import { CircularProgress } from '@mui/material'

export default function PortfolioPage() {
	let { id } = useParams()
	const navigate = useNavigate()
	const icons = iconStyles()

	const [portfolio, setPortfolio] = useState({})
	const [images, setImages] = useState([])
	const [fullScreen, setFullScreen] = useState(false)
	const [imagePosition, setImagePosition] = useState(0)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		axios
			.get(`process.env.REACT_APP_BASE_URL/portfolios/portfolio/${id}`)
			.then((response) => {
				setPortfolio(response.data)
			})
			.catch((error) => {
				console.log(error)
			})
	}, [])

	useEffect(() => {
		axios
			.get(`process.env.REACT_APP_BASE_URL/images/${portfolio.id}`)
			.then((response) => {
				setImages(response.data)
				setLoading(false)
			})
	}, [portfolio])

	const renderImages = () => {
		if (!loading) {
			return images.map((value, index) => {
				return (
					<PortfolioPageImage
						value={value}
						key={value.image_id}
						index={index}
						setFullScreen={setFullScreen}
						setImagePosition={setImagePosition}
					/>
				)
			})
		} else {
			return <CircularProgress sx={{ width: '100%', color: '#c38c0c' }} />
		}
	}

	const handleForwardClick = (e) => {
		e.stopPropagation()
		if (imagePosition === images.length - 1) {
			setImagePosition(0)
			return
		}
		setImagePosition((prevState) => prevState + 1)
	}

	const handleBackClick = (e) => {
		e.stopPropagation()
		if (imagePosition === 0) {
			setImagePosition(images.length - 1)
			return
		}
		setImagePosition((prevState) => prevState - 1)
	}

	const renderFullScreenImages = () => {
		if (fullScreen) {
			return (
				<div
					className='fixed left-0 top-0 w-full h-full flex justify-center items-center bg-black/75 z-50'
					onClick={() => setFullScreen(false)}
				>
					<CloseIcon
						className='!text-5xl cursor-pointer !text-gray-300 hover:!text-white fixed left-4 top-4'
						onClick={() => setFullScreen(false)}
					/>
					<ArrowBackIosNewIcon
						className='!text-5xl fixed top-1/2 left-4 -translate-y-1/2 z-40 cursor-pointer !text-gray-300 hover:!text-white'
						onClick={(e) => handleBackClick(e)}
					/>
					<img
						src={images[imagePosition].image_url}
						alt={portfolio.firstname + portfolio.lastname}
						className='max-w-10/12 h-5/6 md:h-auto'
						onClick={(e) => e.stopPropagation()}
					/>
					<ArrowForwardIosIcon
						className='!text-5xl fixed top-1/2 right-4 -translate-y-1/2 z-40 cursor-pointer !text-gray-300 hover:!text-white'
						onClick={(e) => handleForwardClick(e)}
					/>
				</div>
			)
		}
		return null
	}

	return (
		<div className='screen-nav'>
			<ArrowBackIcon
				className={cx(icons.backIcon, 'hover:bg-zinc-700')}
				onClick={() => navigate(-1)}
			/>
			<h3 className='text-5xl my-16 font-miller'>
				{portfolio.firstname} {portfolio.lastname}
			</h3>
			{images.length !== 0 ? (
				<div className='w-full px-12 flex flex-wrap justify-center'>
					{renderImages()}
				</div>
			) : null}
			{portfolio.review ? (
				<div className='flex flex-col items-center px-8'>
					<h3 className='text-4xl my-12'>{portfolio.firstname}'s Review</h3>
					<p className='text-2xl mb-12 max-w-prose'>"{portfolio.review}"</p>
				</div>
			) : null}

			{renderFullScreenImages()}
		</div>
	)
}
