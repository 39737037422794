import React, { useContext, useEffect, useState } from 'react'
import ToastMessage from '../components/Common/ToastMessage'
import ContactForm from '../components/Contact/ContactForm'
import { AppContext } from '../utils/context'

export default function Contact() {
	const { setPageActive } = useContext(AppContext)

	const [successfullySent, setSuccessfullySent] = useState(false)
	const [toastVisible, setToastVisible] = useState(false)
	const [toastMessage, setToastMessage] = useState('')

	useEffect(() => {
		setPageActive('contact')
	}, [])

	return (
		<div className='screen-nav flex flex-col items-center px-8'>
			<ToastMessage
				success={successfullySent}
				visible={toastVisible}
				msg={toastMessage}
			/>
			<h3 className='text-6xl my-12 font-miller'>Contact Me</h3>
			<p className='max-w-prose text-center text-xl'>
				Thank you for considering me to bring your artistic vision to life through
				personalized portrait photography! Whether you're ready to schedule a
				session or simply want to learn more about my unique approach to capturing
				the essence of musicians, I'm here to answer your questions and transform
				your vision into reality.
			</p>
			<br />
			<p className='max-w-prose text-center text-xl mb-12'>
				Contacting me is easy—simply complete the form below or reach out to me
				directly at seanreynoldsphotography@gmail.com. If you're ready to take the
				next step, let's schedule a free 15-minute phone consultation to explore how
				we can create an unforgettable portrait together.
			</p>
			<p className='max-w-prose text-center text-xl mb-12'>
				I'm looking forward to connecting with you and embarking on a creative
				journey, exploring the magic of music through personalized portrait
				photography.
			</p>
			<div className='mb-12'>
				<ContactForm
					setSuccessfullySent={setSuccessfullySent}
					setToastVisible={setToastVisible}
					setToastMessage={setToastMessage}
				/>
			</div>
		</div>
	)
}
