import React, { useContext } from 'react'
import cx from 'classnames'
import { AppContext } from '../../utils/context'

export default function InvestmentSegment({ children, img, left, bg }) {
	const { mobile } = useContext(AppContext)

	return (
		<div className='w-full'>
			<div
				className={cx(
					'w-full flex',
					mobile ? 'flex-col-reverse' : left ? 'flex-row-reverse' : ''
				)}
				style={{ flexDirection: mobile ? 'column-reverse' : '' }}
			>
				<div className={cx('flex', mobile ? 'w-full' : 'w-3/6')}>
					<img className='min-w-full h-full object-cover' src={img} alt={img} />
				</div>
				<div
					className={cx(
						`flex flex-col items-start justify-center text-black py-6 ${bg}`,
						mobile ? 'w-full' : 'w-3/6'
					)}
					// style={{ backgroundColor: bg }}
				>
					<div className='w-full flex justify-evenly flex-wrap'>{children}</div>
				</div>
			</div>
		</div>
	)
}
