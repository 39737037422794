import React, { useEffect } from 'react'

export default function PortfolioPageImage({
	value,
	index,
	setFullScreen,
	setImagePosition,
}) {
	return (
		<img
			className='w-4/12 min-w-xs p-3 md:w-full cursor-pointer hover:scale-[1.025] transition-all'
			src={value.image_url}
			alt={value.image_url}
			onClick={() => {
				setFullScreen(true)
				setImagePosition(index)
			}}
		/>
	)
}
