import React, { useEffect, useState } from 'react'

export const AppContext = React.createContext()

export default function Context(props) {
	const [mobile, setMobile] = useState(false)
	const [pageActive, setPageActive] = useState('')

	useEffect(() => {
		if (window.innerWidth <= 878) {
			setMobile(true)
		} else {
			setMobile(false)
		}
		window.addEventListener('resize', () => {
			if (window.innerWidth <= 878) {
				setMobile(true)
			} else {
				setMobile(false)
			}
		})
	}, [])

	const appContextValue = { mobile, pageActive, setPageActive }

	return (
		<AppContext.Provider value={appContextValue}>
			{props.children}
		</AppContext.Provider>
	)
}
