import React, { useContext, useEffect, useState } from 'react'
import ContactForm from '../components/Contact/ContactForm'
import Divider from '../components/Divider'
import Hero from '../components/Home/Hero'
import HomeSegment from '../components/Home/HomeSegment'
import cx from 'classnames'
import { useRef } from 'react'
import { AppContext } from '../utils/context'
import jonathan from '../assets/img/jpg/Jonathan.jpg'
import alyssa from '../assets/img/jpg/Alyssa.jpg'
import seanHead from '../assets/img/jpg/sean-head.jpg'
import ToastMessage from '../components/Common/ToastMessage'
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance'
import CameraIcon from '@mui/icons-material/Camera'
import GroupsIcon from '@mui/icons-material/Groups'
import { Link } from 'react-router-dom'

export default function Home() {
	const contactRef = useRef()

	const [scrolledPast, setScrolledPast] = useState([])
	const [successfullySent, setSuccessfullySent] = useState(false)
	const [toastVisible, setToastVisible] = useState(false)
	const [toastMessage, setToastMessage] = useState('')

	const { setPageActive } = useContext(AppContext)

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (
				window.scrollY + (window.innerHeight - 200) >
					contactRef?.current?.offsetTop &&
				scrolledPast === false
			) {
				setScrolledPast(true)
			}
		})
		setPageActive('home')
	}, [])

	return (
		<div>
			<ToastMessage
				success={successfullySent}
				visible={toastVisible}
				msg={toastMessage}
			/>
			<Hero />
			<div className='px-2'>
				<div className={`w-full flex justify-center my-12`}>
					<p className='max-w-prose text-center text-xl'>
						From those awkward family photos to the dreaded unprepared candid shots,
						just thinking about a photoshoot might make you uneasy. Yet, you're aware
						of how essential standout images are for your online presence.
					</p>
				</div>
				<div className='w-full flex justify-center my-12'>
					<p className='max-w-prose text-center text-4xl text-magenta'>
						That’s where I come in.
					</p>
				</div>
				<div className={`w-full flex justify-center my-12`}>
					<p className='max-w-prose text-center text-xl'>
						I've walked in those shoes. My mission? Making your photoshoot not just a
						task, but a joyous moment where your true self shines.
					</p>
				</div>
			</div>

			{/* Services row */}
			<div className='bg-[#191919] py-16 px-8 flex flex-col items-center'>
				<h2 className='text-5xl mb-4'>My Services</h2>
				<div className='flex lg:flex-col'>
					<div className='w-1/3 lg:w-full p-6 flex flex-col justify-center items-center'>
						<div className='w-[48px] h-[48px] bg-[#54565b] rounded-full flex justify-center items-center mb-4	'>
							<CameraEnhanceIcon fontSize='large' />
						</div>
						<h4 className='text-[28px] font-bold mb-4'>Personalized Portraits</h4>
						<p className='font-normal text-xl max-w-[36ch]'>
							Capture the essence of individuality. Ideal for those who value
							authenticity and wish to stand out.
						</p>
					</div>
					<div className='w-1/3 lg:w-full p-6 flex flex-col justify-center items-center'>
						<div className='w-[48px] h-[48px] bg-[#54565b] rounded-full flex justify-center items-center mb-4	'>
							<CameraIcon fontSize='large' />
						</div>
						<h4 className='text-[28px] font-bold mb-4'>Specialized Headshots</h4>
						<p className='font-normal text-xl max-w-[36ch]'>
							Beyond the conventional. Crafted with precision to ensure every look
							communicates more than just a face.
						</p>
					</div>
					<div className='w-1/3 lg:w-full p-6 flex flex-col justify-center items-center'>
						<div className='w-[48px] h-[48px] bg-[#54565b] rounded-full flex justify-center items-center pb-1 mb-4	'>
							<GroupsIcon fontSize='large' />
						</div>
						<h4 className='text-[28px] font-bold mb-4'>Group Photography</h4>
						<p className='font-normal text-xl max-w-[36ch]'>
							Celebrate togetherness. Perfect for any group, big or small, wishing to
							capture their shared moments with elegance.
						</p>
					</div>
				</div>
				<div>
					<Link to={'/services'}>
						<p className='text-2xl text-yellow underline cursor-pointer'>
							Learn more
						</p>
					</Link>
				</div>
			</div>
			{/* End of services row */}

			<HomeSegment path='/about' heading='About Me' img={seanHead}>
				Ensuring your photoshoot is enjoyable is my top priority. Dive deeper to
				discover the person behind the lens. From roaming Cincinnati's vibrant
				streets to jamming with fellow musicians and savoring a local brew, there's
				more to my story. Explore to get acquainted!
			</HomeSegment>
			<span className='lg:hidden'>
				<Divider />
			</span>
			<HomeSegment path='/portfolio' heading='Portfolio' img={jonathan} left>
				Dive into my portfolio and see the range of my work. From individuals to
				groups, each photo is tailored to reflect authenticity. Let's collaborate to
				capture your unique essence.
			</HomeSegment>
			<span className='lg:hidden'>
				<Divider />
			</span>
			<div
				className={cx(
					'w-full py-16 px-8 flex lg:flex-col',
					scrolledPast ? 'animate-fadeIn' : 'opacity-0'
				)}
				ref={contactRef}
			>
				<div className='w-6/12 lg:w-full lg:pb-8 flex flex-col items-center'>
					<h3 className='text-6xl pb-4'>Contact Me</h3>
					<p className='max-w-md text-center text-xl'>
						If you're interested in scheduling a session or learning more about my
						services, please visit my contact page. I offer a wide range of services,
						including portrait photography for musicians, event photography, and more.
						Contact me to schedule a free 15-minute phone consultation to discuss your
						needs and how I can help bring your vision to life.
					</p>
				</div>
				<div className='w-6/12 flex justify-center lg:w-full'>
					<ContactForm
						setSuccessfullySent={setSuccessfullySent}
						setToastVisible={setToastVisible}
						setToastMessage={setToastMessage}
					/>
				</div>
			</div>
		</div>
	)
}
