import { Button, CircularProgress } from '@mui/material'
import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import PortfolioPreview from '../components/Portfolio/PortfolioPreview'
import { buttonStyles } from '../styles/Material-UI/Buttons/FormButton'
import { Box } from '@mui/system'
import { AppContext } from '../utils/context'

export default function Portfolio() {
	const [portfolios, setPortfolios] = useState([])
	const [loading, setLoading] = useState(true)

	const { setPageActive } = useContext(AppContext)

	useEffect(() => {
		axios.get(process.env.REACT_APP_BASE_URL + '/portfolios').then((response) => {
			setPortfolios(response.data)
			setTimeout(() => {
				setLoading(false)
			}, 1000)
		})
		setPageActive('portfolio')
	}, [])

	const buttons = buttonStyles()

	const renderPortfolios = () => {
		if (!loading) {
			if (portfolios.length >= 1) {
				return portfolios.map((value, key) => {
					return <PortfolioPreview value={value} key={key} />
				})
			} else {
				return <p>No portfolios found</p>
			}
		} else {
			return <CircularProgress sx={{ width: '100%', color: '#c38c0c' }} />
		}
	}

	return (
		<div className='screen-nav mb-8'>
			<div className='w-full flex flex-col items-center px-8'>
				<h3 className='text-6xl my-12 font-miller'>Portfolio</h3>
				<p className='w-full max-w-prose mb-8 text-2xl'>
					Welcome to my portfolio, featuring captivating portraiture and headshots of
					exceptional musicians! Drawing from my knowledge of musicians and the
					industry, I specialize in crafting portraits that authentically capture
					your essence.
				</p>
				<p className='w-full max-w-prose mb-8 text-2xl'>
					In this portfolio, you'll find a curated selection of my finest work,
					showcasing musicians from diverse genres and styles. If you're a musician
					eager to immortalize your unique style and sound in a portrait, I invite
					you to schedule a free 15-minute phone consultation with me today.
				</p>
				<p className='w-full max-w-prose mb-8 text-2xl'>
					Take your time exploring the gallery, and when you're ready, click the
					button below to schedule your consultation. I'm thrilled to hear from you
					and begin crafting your masterpiece.
				</p>
				<Link to='/contact'>
					<Button
						variant='contained'
						className={cx(buttons.bookButton, 'bg-magenta-reg magenta-hover')}
					>
						Request Info
					</Button>
				</Link>
				<div className='w-full p-16 flex justify-center flex-wrap'>
					{renderPortfolios()}
				</div>
			</div>
		</div>
	)
}
