import React, { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import axios from 'axios'
import { Button } from '@mui/material'
import cx from 'classnames'

import { buttonStyles } from '../../styles/Material-UI/Buttons/FormButton'
// import ConfirmModal from '../Common/ConfirmModal'

export default function PortfolioPreview({
	value,
	edit,
	confirmDelete,
	setIdToDelete,
	getPortfolioToEdit,
}) {
	const navigate = useNavigate()
	const buttons = buttonStyles()

	const [hover, setHover] = useState(false)

	return (
		<div className='m-2 p-2 w-3/12 min-w-xs h-80 overflow-hidden relative'>
			{edit ? (
				<CloseIcon
					onClick={() => {
						setIdToDelete(value.id)
						confirmDelete()
					}}
					className='absolute z-10 -top-0 -right-0 bg-red-700/90 hover:bg-red-700 rounded-full p-1 !text-3xl cursor-pointer'
				/>
			) : null}
			<div
				className='w-full h-full cursor-pointer relative'
				onClick={!edit ? () => navigate(`/portfolio/${value.id}`) : null}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				{edit ? (
					<div
						className={
							hover
								? 'bg-black/50 absolute w-full h-full flex justify-center items-center cursor-default transition-all'
								: 'absolute w-full h-full flex justify-center items-center cursor-default opacity-0 transition-all'
						}
					>
						<Button
							variant='outlined'
							className={cx(buttons.cancelButton, '!mr-3 !text-yellow !text-2xl')}
							onClick={() => navigate(`/portfolio/${value.id}`)}
						>
							Visit
						</Button>
						<Button
							variant='outlined'
							className={cx(buttons.cancelButton, '!ml-3 !text-yellow !text-2xl')}
							onClick={() => getPortfolioToEdit(value.id)}
						>
							Edit
						</Button>
					</div>
				) : null}

				<img
					src={value.img}
					alt='img'
					className='min-w-full object-cover min-h-full'
				/>
			</div>
		</div>
	)
}
