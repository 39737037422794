import React from 'react'

export default function SessionInfo({ type, price, children, large }) {
	return (
		<>
			<div className={large ? '8/12' : 'w-5/12'}>
				<p className='text-2xl font-miller mt-6'>{type}</p>
				<p className='text-2xl font-miller mb-4'>${price}</p>
				<p className='max-w-xs mx-auto text-base'>{children}</p>
			</div>
		</>
	)
}
