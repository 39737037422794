import React, { useContext, useEffect } from 'react'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import InvestmentSegment from '../components/Investment/InvestmentSegment'
import SessionInfo from '../components/Investment/SessionInfo'
import Divider from '../components/Divider'
import { buttonStyles } from '../styles/Material-UI/Buttons/FormButton'
import { AppContext } from '../utils/context'
import rico from '../assets/img/jpg/Rico.jpg'
import austinKirstin from '../assets/img/jpg/Austin-Kirstin.jpg'

export default function Investment() {
	const buttons = buttonStyles()

	const { setPageActive } = useContext(AppContext)

	useEffect(() => {
		setPageActive('investment')
	})

	return (
		<div className='w-full'>
			<h3 className='text-6xl my-16 font-miller'>Services</h3>
			<div className='w-full'>
				<InvestmentSegment bg='bg-yellow' img={rico}>
					<h3 className='text-5xl font-miller w-full'>Portraits</h3>
					<SessionInfo type='Half Session' price={125}>
						30-minute portrait session, includes an online gallery of at least 10
						high-resolution downloadable photos, and shared copyrights of all edited
						gallery images.
					</SessionInfo>
					<SessionInfo type='Full Session' price={200}>
						60-minute portrait session, includes an online gallery of at least 15
						high-resolution downloadable photos, and shared copyrights of all edited
						gallery images.
					</SessionInfo>
					<SessionInfo type='Family Session' price={300} large>
						60-minute portrait session for up to four, additional $10/person above
						four, includes an online gallery of at least 20 high-resolution
						downloadable photos, and shared copyrights of all edited gallery images.
					</SessionInfo>
				</InvestmentSegment>
				<InvestmentSegment left bg='bg-magenta-light' img={austinKirstin}>
					<h3 className='text-5xl font-miller w-full'>Couples</h3>
					<SessionInfo type='Half Session' price={150}>
						30-minute couples' session, includes online gallery of high-resolution
						downloadable photos, and shared copyrights of all gallery images.
					</SessionInfo>
					<SessionInfo type='Full Session' price={250}>
						60-minute couples' session, includes online gallery of high-resolution
						downloadable photos, and shared copyrights of all gallery images.
					</SessionInfo>
					<SessionInfo type='Extra Time' price={50} large>
						Need extra time? No problem! It's $50 per extra half hour.
					</SessionInfo>
				</InvestmentSegment>
				<div className='p-8'>
					{/* <Divider />
					<h3 className='py-5 text-3xl cursor-pointer'>
						Request Detailed Pricing and Availability
					</h3>
					<Divider /> */}
					<Link to='/contact'>
						<Button
							variant='contained'
							className={cx(buttons.bookButton, 'bg-magenta-reg magenta-hover')}
						>
							Request Availability
						</Button>
					</Link>
				</div>
			</div>
		</div>
	)
}
