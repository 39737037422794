import { makeStyles } from '@mui/styles'

export const iconStyles = makeStyles((theme) => ({
	instaIcon: {
		cursor: 'pointer',
		fontSize: '1.875rem !important',
		'&:hover': {
			color: '#fb3958',
		},
	},
	imgDelete: {
		position: 'absolute',
		right: '7px',
		top: '7px',
		padding: '3px',
		fontSize: '1.25rem !important',
		backgroundColor: 'red !important',
		// backgroundColor: 'rgba(248, 248, 248, .8)',
		borderRadius: '50%',
		border: '1px solid black',
		color: 'black',
		cursor: 'pointer',
		transition: '.1s ease-in-out background-color',

		'&:hover': {
			backgroundColor: 'rgba(248, 248, 248, 1)',
		},
	},
	addPhotos: {
		padding: '7px',
		borderRadius: '50%',
		fontSize: '2.25rem !important',
		marginBottom: '3px',
	},
	backIcon: {
		position: 'absolute',
		left: '10px',
		top: '60px',
		backgroundColor: 'transparent',
		color: 'white',
		borderRadius: '50%',
		fontSize: '3rem !important',
		cursor: 'pointer',
		transition: '.1s ease-in-out all !important',

		'&:hover': {
			// backgroundColor: 'gray',
		},
	},
}))
