import { makeStyles } from '@mui/styles'
import { color } from '@mui/system'

export const buttonStyles = makeStyles((theme) => ({
	formButton: {
		width: '100%',
		marginTop: '8px !important',
		padding: '9.25px 16px !important',
	},
	bookButton: {
		fontSize: '1rem !important',
	},
	test: {
		backgorundColor: 'red !important',
	},
	cancelButton: {
		borderColor: 'hsla(42, 100%, 50%, .5) !important',
		transition: 'all .1s ease-in-out',

		'&:hover': {
			borderColor: 'hsl(42, 100%, 50%) !important',
		},
	},
	outlinedRegular: {
		borderColor: 'hsla(42, 100%, 50%, .5) !important',
		transition: 'all .1s ease-in-out',

		'&:hover': {
			borderColor: 'hsl(42, 100%, 50%) !important',
		},
	},
}))
