import React, { useState, useEffect, useContext } from 'react'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import CloseIcon from '@mui/icons-material/Close'
import { v4 } from 'uuid'
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'
import cx from 'classnames'

import auth from '../firebase-config'
import { inputStyles } from '../styles/Material-UI/Inputs/ContactInput'
import { iconStyles } from '../styles/Material-UI/Icons/Icons'
import { Button } from '@mui/material'
import { buttonStyles } from '../styles/Material-UI/Buttons/FormButton'
import PortfolioPreview from '../components/Portfolio/PortfolioPreview'
import { AppContext } from '../utils/context'
import ConfirmModal from '../components/Common/ConfirmModal'
import ToastMessage from '../components/Common/ToastMessage'

export default function Edit() {
	const classes = inputStyles()
	const buttons = buttonStyles()
	const icons = iconStyles()

	const [tempPortfolioID, setTempPortfolioID] = useState('')
	const [firstname, setFirstname] = useState('')
	const [lastname, setLastname] = useState('')
	const [review, setReview] = useState('')
	const [images, setImages] = useState([])
	const [buttonText, setButtonText] = useState('Add')
	const [portfolioID, setPortfolioID] = useState('')
	const [portfolios, setPortfolios] = useState([])
	const [deleted, setDeleted] = useState(0)
	const [loggedIn, setLoggedIn] = useState(false)
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [user, setUser] = useState({})
	const [open, setOpen] = useState(false)
	const [msg, setMsg] = useState('')
	const [successfulUpload, setSuccessfulUpload] = useState(false)
	const [successfulEdit, setSuccessfulEdit] = useState(false)
	const [successMessage, setSuccessMessage] = useState('')
	const [toastVisible, setToastVisible] = useState(false)
	const [idToDelete, setIdToDelete] = useState('')
	const [imagesToDelete, setImagesToDelete] = useState([])
	const [newImages, setNewImages] = useState([])

	const { setPageActive } = useContext(AppContext)

	onAuthStateChanged(auth, (currentUser) => {
		setUser(currentUser)
	})

	const getPortfolios = () => {
		axios.get(process.env.REACT_APP_BASE_URL + '/portfolios').then((response) => {
			setPortfolios(response.data)
		}, [])
	}

	const getPortfolioToEdit = (id) => {
		axios
			.get(`process.env.REACT_APP_BASE_URL/portfolios/portfolio/${id}`)
			.then((response) => {
				setTempPortfolioID(id)
				setFirstname(response.data.firstname)
				setLastname(response.data.lastname)
				setReview(response.data.review)
				setButtonText('Save')
				getPortfolioImages(id)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const getPortfolioImages = (id) => {
		axios.get(`process.env.REACT_APP_BASE_URL/images/${id}`).then((response) => {
			const data = response.data
			const newData = data.map((image) => {
				return { id: image.image_id, url: image.image_url }
			})

			setImages(newData)
		})
	}

	useEffect(() => {
		setPageActive('edit')
	}, [])

	useEffect(() => {
		window.addEventListener('beforeunload', deleteAllImagesFromCloud)
		getPortfolios()
		submitImages(portfolioID)
	}, [portfolioID])

	useEffect(() => {
		renderPortfolios()
	}, [portfolios])

	useEffect(() => {
		getPortfolios()
	}, [deleted])

	useEffect(() => {
		determineToastSuccess()
	}, [successfulEdit, successfulUpload])

	const login = async () => {
		try {
			const user = await signInWithEmailAndPassword(auth, email, password)
		} catch (error) {
			console.log(error)
		}
	}

	const deleteImage = (img) => {
		setImagesToDelete([...imagesToDelete, img.id])
		if (images.length > 1) {
			setImages(images.filter((image) => image.id !== img.id))
		} else {
			setImages([])
		}
	}

	const deleteImageFromCloud = async (img) => {
		let data = { public_id: img.id }
		try {
			await axios.post(process.env.REACT_APP_BASE_URL + '/images/destroy', data)
		} catch (error) {
			console.log(error)
		}
	}

	const deleteAllImagesFromCloud = () => {
		for (let i = 0; i < images.length; i++) {
			deleteImageFromCloud(images[i])
		}
	}

	const onUpload = async (event) => {
		event.preventDefault()
		let file = event.target.files[0]

		if (file) {
			const formData = new FormData()
			formData.append('file', file)
			formData.append('upload_preset', 'jgxcyq6q')

			let res = await axios.post(
				'https://api.cloudinary.com/v1_1/djfzrtcji/image/upload',
				formData
			)

			const url = res.data.url

			setImages([
				...images,
				{ id: res.data.public_id, name: file.name, file: file, url: url },
			])

			if (buttonText.toLocaleLowerCase() === 'save') {
				setNewImages([
					...newImages,
					{ id: res.data.public_id, name: file.name, file: file, url: url },
				])
			}
		}
	}

	const submitImages = async (id) => {
		for (let i = 0; i < images.length; i++) {
			let data = {
				image_id: images[i].id,
				image_url: images[i].url,
				// name: images[i].name,
				id: id,
			}
			try {
				await axios.post(process.env.REACT_APP_BASE_URL + '/images', data)
				setPortfolioID('')
				setImages([])
			} catch (error) {
				console.log(error)
			}
		}
	}

	const uploadNewImages = async (id) => {
		for (let i = 0; i < newImages.length; i++) {
			let data = {
				image_id: newImages[i].id,
				image_url: newImages[i].url,
				// name: images[i].name,
				id: id,
			}
			try {
				await axios.post(process.env.REACT_APP_BASE_URL + '/images', data)
				setPortfolioID('')
				setImages([])
				setNewImages([])
			} catch (error) {
				console.log(error)
			}
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		let data = {}
		data.id = v4()
		data.firstname = firstname
		data.lastname = lastname
		data.review = review
		data.img = images.length >= 1 ? images[0].url : null
		try {
			await axios.post(process.env.REACT_APP_BASE_URL + '/portfolios', data)
			setFirstname('')
			setLastname('')
			setReview('')
			// Triggers images upload
			setPortfolioID(data.id)

			setSuccessMessage('Portfolio successfully uploaded!')
			setSuccessfulUpload(true)
			setToastVisible(true)

			setTimeout(() => {
				setToastVisible(false)
				setTimeout(() => {
					setSuccessfulUpload(false)
					setSuccessMessage('')
				}, 500)
			}, 3000)
		} catch (error) {
			setSuccessMessage('Unexpected error, please try again later')
			setSuccessfulEdit(false)
			setToastVisible(true)

			setTimeout(() => {
				setToastVisible(false)
				setTimeout(() => {
					setSuccessfulEdit(false)
					setSuccessMessage('')
				}, 500)
			}, 3000)
			console.log('error')
		}
	}

	const handleSaveChanges = async (e) => {
		e.preventDefault()
		let data = {}
		data.portfolioID = tempPortfolioID
		data.firstname = firstname
		data.lastname = lastname
		data.review = review
		data.img = images.length >= 1 ? images[0].url : null

		try {
			await axios.post(process.env.REACT_APP_BASE_URL + '/portfolios/update', data)
			await axios.post(process.env.REACT_APP_BASE_URL + '/images/deleteMany', {
				imagesToDelete: imagesToDelete,
			})
			setFirstname('')
			setLastname('')
			setReview('')
			setImagesToDelete([])
			setImages([])
			setButtonText('Add')
			// Triggers images upload
			uploadNewImages(data.portfolioID)

			setSuccessMessage('Portfolio successfully updated!')
			setSuccessfulEdit(true)
			setToastVisible(true)

			setTimeout(() => {
				setToastVisible(false)
				setTimeout(() => {
					setSuccessfulEdit(false)
					setSuccessMessage('')
				}, 500)
			}, 3000)
		} catch (error) {
			setSuccessMessage('Unexpected error, please try again later')
			setSuccessfulEdit(false)
			setToastVisible(true)

			setTimeout(() => {
				setToastVisible(false)
				setTimeout(() => {
					setSuccessfulEdit(false)
					setSuccessMessage('')
				}, 500)
			}, 3000)
			console.log(error)
		}
	}

	const files = images.map((image) => (
		<div className='img-upload-small' key={image.id}>
			<img src={image.url} alt={image.name} className='img-thumb' />
			<CloseIcon
				className='absolute z-10 top-[2px] right-[2px] bg-red-700/70 hover:bg-red-700 rounded-full p-1 !text-xl cursor-pointer'
				onClick={() => deleteImage(image)} // TODO: IF IT IS EDITING, MAKE CONFIRM MODAL POP UP AND DELETE FROM DB AND CLOUDINARY
			/>
		</div>
	))

	const renderImageDrop = () => {
		return (
			<div className='mb-1 my-2'>
				{images.length < 1 ? (
					<label className='img-upload'>
						<AddPhotoAlternateIcon className={icons.addPhotos} />
						Add photos
						<input
							type='file'
							name='image'
							onChange={(event) => {
								onUpload(event)
							}}
							className='hidden-input'
						/>
					</label>
				) : (
					<div className='img-upload-container'>
						{files}
						{
							<label className='img-upload-small back-silver'>
								<AddPhotoAlternateIcon className={icons.addPhotos} />
								Add photo
								<input
									type='file'
									name='image'
									onChange={(event) => {
										onUpload(event)
									}}
									className='hidden-input'
								/>
							</label>
						}
					</div>
				)}
			</div>
		)
	}

	const handleDeleteChange = () => {
		setDeleted((prevState) => prevState + 1)
	}

	const renderPortfolios = () => {
		return portfolios.map((value, key) => {
			return (
				<PortfolioPreview
					value={value}
					key={key}
					confirmDelete={confirmDelete}
					setIdToDelete={setIdToDelete}
					getPortfolioToEdit={getPortfolioToEdit}
					edit
				/>
			)
		})
	}

	const confirmDelete = () => {
		setOpen(true)
		setMsg('Are you sure you want to delete this portfolio?')
	}

	const deletePortfolio = (id) => {
		handleDeleteChange()
		axios
			.post(`${process.env.REACT_APP_BASE_URL}/portfolios/delete`, {
				id: id,
			})
			.then((response) => {
				setOpen(false)
				setMsg('')

				setPortfolios(portfolios.filter((portfolio) => portfolio.id !== id))

				setSuccessMessage('Portfolio successfully deleted!')
				setSuccessfulEdit(true)
				setToastVisible(true)

				setTimeout(() => {
					setToastVisible(false)
					setTimeout(() => {
						setSuccessfulEdit(false)
						setSuccessMessage('')
					}, 500)
				}, 3000)
			})
			.catch((error) => {
				setSuccessMessage('Unexpected error, please try again later')
				setSuccessfulEdit(false)
				setToastVisible(true)

				setTimeout(() => {
					setToastVisible(false)
					setTimeout(() => {
						setSuccessfulEdit(false)
						setSuccessMessage('')
					}, 500)
				}, 3000)
				console.log(error)
			})
	}

	const closeModal = () => {
		setOpen(false)
		setMsg('')
	}

	const determineToastSuccess = () => {
		if (successfulUpload || successfulEdit) return true

		return false
	}

	return (
		<div className='screen-nav flex flex-col items-center px-4'>
			<ConfirmModal
				msg={msg}
				open={open}
				id={idToDelete}
				close={closeModal}
				deletePortfolio={deletePortfolio}
			/>
			<ToastMessage
				success={successfulEdit || successfulUpload}
				visible={toastVisible}
				msg={successMessage}
			/>
			{user ? (
				<>
					<h3 className='text-4xl my-12'>Add a Portfolio</h3>
					<form
						className='w-full max-w-sm min-w-xs'
						onSubmit={
							buttonText.toLocaleLowerCase() === 'add'
								? (e) => handleSubmit(e)
								: (e) => handleSaveChanges(e)
						}
					>
						<TextField
							label='First Name'
							variant='outlined'
							fullWidth
							margin='dense'
							value={firstname}
							onChange={(e) => setFirstname(e.target.value)}
							className={
								firstname
									? [classes.contactInput, classes.forceHighlight]
									: classes.contactInput
							}
							required
						/>
						<TextField
							label='Last Name'
							variant='outlined'
							fullWidth
							margin='dense'
							value={lastname}
							onChange={(e) => setLastname(e.target.value)}
							className={
								lastname
									? [classes.contactInput, classes.forceHighlight]
									: classes.contactInput
							}
							required
						/>
						<TextField
							label='Review'
							variant='outlined'
							fullWidth
							margin='dense'
							value={review}
							onChange={(e) => setReview(e.target.value)}
							className={
								review
									? [classes.contactInput, classes.forceHighlight, classes.forceBorder]
									: classes.contactInput
							}
							multiline
							minRows='2'
							maxRows='5'
						/>
						{renderImageDrop()}
						<Button
							variant='contained'
							className={cx(buttons.formButton, 'bg-magenta-reg magenta-hover')}
							type='submit'
						>
							{buttonText}
						</Button>
					</form>
					{portfolios.length >= 1 ? (
						<div className='w-full p-16 flex justify-center flex-wrap'>
							<h3 className='text-4xl mb-12 w-full'>Your Portfolios</h3>
							{renderPortfolios()}
						</div>
					) : null}
				</>
			) : (
				<div>
					<h3 className='text-4xl my-12'>Log In</h3>
					<form className='w-full max-w-sm min-w-xs'>
						<TextField
							label='Email address'
							variant='outlined'
							fullWidth
							margin='dense'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							className={
								email
									? [classes.contactInput, classes.forceHighlight]
									: classes.contactInput
							}
							required
						/>
						<TextField
							label='Password'
							variant='outlined'
							fullWidth
							margin='dense'
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							type='password'
							className={
								password
									? [classes.contactInput, classes.forceHighlight]
									: classes.contactInput
							}
							required
						/>
						<Button
							variant='contained'
							className={cx(buttons.formButton, 'bg-magenta-reg magenta-hover')}
							onClick={login}
						>
							Log In
						</Button>
					</form>
				</div>
			)}
		</div>
	)
}
