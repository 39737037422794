import { Button } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

export default function HomeSegment({ path, img, heading, left, children }) {
	const sectionRef = useRef()

	const [scrolledPast, setScrolledPast] = useState(false)

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (
				window.scrollY + (window.innerHeight - 200) >
					sectionRef?.current?.offsetTop &&
				scrolledPast === false
			) {
				setScrolledPast(true)
			}
		})
	}, [])

	return (
		<div
			className={scrolledPast ? 'animate-fadeIn' : 'opacity-0'}
			ref={sectionRef}
		>
			<div
				className={
					left
						? 'w-full flex flex-row-reverse p-16 lg:flex-col-reverse lg:items-center lg:p-0'
						: 'w-full flex p-16 lg:flex-col-reverse lg:i0tems-center lg:p-0'
				}
			>
				<div className='w-6/12 flex justify-center lg:w-full'>
					<img
						className='max-w-full object-contain'
						src={img}
						width='550px'
						alt={img}
					/>
				</div>
				<div className='w-6/12 flex flex-col justify-center items-center px-8 lg:w-full lg:py-12 lg:px-2'>
					<p className='max-w-sm text-xl'>{children}</p>
					<Link to={path}>
						<Button
							variant='outlined'
							className='!text-xl !border-yellow/60 !text-yellow !mt-8 transition-all hover:!border-yellow'
							endIcon={<ArrowRightIcon className='!text-3xl' />}
						>
							{heading}
						</Button>
					</Link>
				</div>
			</div>
		</div>
	)
}
